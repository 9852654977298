var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "my-1 mb-3", attrs: { lg: "12" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase",
                  attrs: { size: "md", variant: "danger" },
                  on: { click: _vm.onCodesImportClick },
                },
                [_vm._v("Import Approval Codes")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "approvalCodesImportModal",
          attrs: {
            id: "approvalCodesImportModal",
            "hide-header": "",
            "hide-footer": "",
          },
        },
        [
          _c("h3", { staticClass: "mb-4" }, [_vm._v("Import approval codes")]),
          _vm._v(" "),
          _c(
            "b-form",
            {
              staticClass: "av-tooltip tooltip-label-bottom",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onCodesImportSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "col-12 mb-4" },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("label", { staticClass: "pl-0 mb-2 col-12" }, [
                            _vm._v("Select file: "),
                          ]),
                          _vm._v(" "),
                          _c("b-form-file", {
                            attrs: {
                              accept:
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            },
                            on: { change: _vm.onFileChange },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          attrs: {
                            variant: "primary",
                            size: "lg",
                            type: "submit",
                          },
                        },
                        [_c("span", { staticClass: "label" }, [_vm._v("Save")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }